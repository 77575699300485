// OwnerProfile.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "./CustomTooltip";
import Avatar from "react-avatar";
import { getBaseUrl } from "../../config/environment";
import "./OwnerProfile.scss";

const OwnerProfile = ({ owner }) => {
  const navigate = useNavigate();

  return (
    <span
      className="owner-profile me-1"
      data-testid="owner-profile"
      onClick={() =>
        navigate(`/employee_relation?id=${owner.attributes["employee-id"]}`)
      }>
      {owner?.attributes?.employee?.data.attributes["profile-picture"] ? (
        <CustomTooltip
          name={owner?.attributes?.employee?.data?.attributes["preferred-name"]}
          orgCode={
            owner?.attributes?.employee?.data?.attributes["employee-org-master"]
              .data.attributes["org-title"]
          }>
          <img
            className="profile-img my-auto"
            src={`${getBaseUrl()}${
              owner?.attributes?.employee?.data.attributes["profile-picture"]
            }`}
            alt="User Img"
            data-testid="profile-img"
          />
        </CustomTooltip>
      ) : (
        <CustomTooltip
          name={owner?.attributes?.employee?.data?.attributes["preferred-name"]}
          orgCode={
            owner?.attributes?.employee?.data?.attributes["employee-org-master"]
              .data.attributes["org-title"]
          }>
          <Avatar
            name={
              owner?.attributes?.employee?.data?.attributes["preferred-name"]
            }
            orgCode={
              owner?.attributes?.employee?.data?.attributes[
                "employee-org-master"
              ].data.attributes["org-title"]
            }
            size="32"
            round={true}
          />
        </CustomTooltip>
      )}
    </span>
  );
};

export default OwnerProfile;
