import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageSvgs from "../../ImageSvgs";
import Loader from "../../Loader/Loader";
import ProcessViewContent from "./ProcessViewContent";
import { getData } from "../../../services/apiService";
import { getHostUrl } from "../../../config/environment";
import { debounce } from "lodash";
import { UserModal } from "../../../utils/processUtil";
import { ProcessOrgDetailModal } from "./ProcessOrgDetailModal";
// import ProcessFilterDropdown from "../ProcessUserPage/ProcessFilterDropdown";
import GenericFilter from "../../CommonComponents/GenericFilter/GenericFilter";
import "./ProcessView.scss";

export default function ProcessView() {
  const [activeBtn, setActiveBtn] = useState("cardView");
  const [processDetails, setProcessDetails] = useState([]);
  const [included, setIncluded] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [activePro, setActivePro] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finalOrgQuery, setFinalOrgQuery] = useState();
  const [finalGrpQuery, setFinalGrpQuery] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [usersData, setUsersData] = useState([]);
  const [showEmpModal, setShowEmpModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [orgValues, setOrgValues] = useState([]);
  const [query, setQuery] = useState("");
  const [dropdownShow, setDropdownShow] = useState(false);
  const [groupQuery, setGroupQuery] = useState([]);
  const [orgQuery, setOrgQuery] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [allLoading, setAllLoading] = useState(false);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [selectedValue, setSelectedValue] = useState([{ name: "No", id: 1 }]);
  const [showConnection, setShowConnection] = useState(selectedValue);
  const adminCheck = sessionStorage.getItem("adminCheck");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProcessDetailsData = async () => {
      setAllLoading(true);
      const community = `${getHostUrl()}community?use_pagination=false`;
      const training = `${getHostUrl()}training?use_pagination=false`;
      const application = `${getHostUrl()}application?use_pagination=false`;
      const orgData = `${getHostUrl()}organization/search?search=SE FIN GS&use_pagination=false`;

      try {
        const [res3, res4, res5, res6] = await Promise.all([
          getData(community),
          getData(training),
          getData(application),
          getData(orgData),
        ]);

        sessionStorage.setItem("communityAll", JSON.stringify(res3.data));
        sessionStorage.setItem("trainingAll", JSON.stringify(res4.data));
        sessionStorage.setItem("applicationAll", JSON.stringify(res5.data));
        sessionStorage.setItem("orgAll", JSON.stringify(res6.data));
      } catch (err) {
        if (err.response?.status === 401) {
          console.error("Unauthorized access:", err);
        } else {
        }
      } finally {
        setAllLoading(false);
      }
    };

    fetchProcessDetailsData();
  }, []);

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const groupEndpoint = `${getHostUrl()}process/groups`;
        const grpResponse = await getData(groupEndpoint);
        if (grpResponse?.status === 200) {
          sessionStorage.setItem(
            "allProcessGroups",
            JSON.stringify(grpResponse.data.data)
          );
          setGroupList(grpResponse.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchGroupData();
  }, []);

  const options = [
    { name: "No", id: 1 },
    { name: "Yes", id: 2 },
  ];

  const createQueryParams = (
    activeBtn,
    currentPage,
    query,
    finalGrpQuery,
    finalOrgQuery
  ) => {
    const queryParams = new URLSearchParams();
    const itemsPerPage = 20;

    if (activeBtn === "mapView") {
      queryParams.set(
        "template",
        getTemplate("mapView", query, finalGrpQuery, finalOrgQuery)
      );
      queryParams.set("include", "child_processes,parent_processes,orgs");
      queryParams.set("use_pagination", "false");
    } else if (activeBtn === "cardView") {
      queryParams.set(
        "template",
        getTemplate("cardView", query, finalGrpQuery, finalOrgQuery)
      );
      queryParams.set("include", "owners,orgs");
      queryParams.set("use_pagination", "true");
      queryParams.set("page", currentPage.toString());
      queryParams.set("per_page", itemsPerPage.toString());
    } else {
      queryParams.set(
        "template",
        getTemplate(null, query, finalGrpQuery, finalOrgQuery)
      );
      queryParams.set("include", "owners,orgs");
      queryParams.set("use_pagination", "true");
      queryParams.set("page", currentPage.toString());
    }

    addOptionalParams(queryParams, query, finalGrpQuery, finalOrgQuery);

    return queryParams;
  };

  const getTemplate = (view, query, finalGrpQuery, finalOrgQuery) => {
    if (query || finalGrpQuery?.length > 0 || finalOrgQuery?.length > 0) {
      return "process_search";
    }
    return view === "mapView" ? "process_map_view" : "process_card_view";
  };

  const addOptionalParams = (
    queryParams,
    query,
    finalGrpQuery,
    finalOrgQuery
  ) => {
    if (finalGrpQuery?.length > 0) {
      queryParams.set("group", finalGrpQuery[0]?.id.toString());
    }
    if (finalOrgQuery?.length > 0) {
      queryParams.set("org_id", finalOrgQuery[0]?.id.toString());
    }
    if (query) {
      queryParams.set("search", query);
    }
  };

  const constructEndpoint = (queryParams) => {
    const baseUrl = getHostUrl();
    const path =
      query || finalGrpQuery?.length > 0 || finalOrgQuery?.length > 0
        ? "process/search"
        : "process";
    return `${baseUrl}${path}?${queryParams.toString()}`;
  };

  const handleResponse = (res) => {
    setProcessDetails(res.data.data);
    setIncluded(res.data.included);

    if (activeBtn !== "mapView") {
      setTotalPage(res.data.meta["page-count"]);
      setCurrentPage(res.data.meta.page);
    }
  };

  const processRes = useCallback(async () => {
    setLoading(true);

    try {
      const queryParams = createQueryParams(
        activeBtn,
        currentPage,
        query,
        finalGrpQuery,
        finalOrgQuery
      );
      const endpoint = constructEndpoint(queryParams);
      const res = await getData(endpoint);

      if (res?.status === 200) {
        handleResponse(res);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setDeleteFlag(false);
    }
    // eslint-disable-next-line
  }, [activeBtn, currentPage, query, finalGrpQuery, finalOrgQuery, deleteFlag]);

  // eslint-disable-next-line
  const debouncedGetResponse = useCallback(debounce(processRes, 400), [
    processRes,
  ]);

  useEffect(() => {
    debouncedGetResponse();

    return () => {
      debouncedGetResponse.cancel();
    };
  }, [debouncedGetResponse]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const setUsers = (list) => {
    let userList = [];
    // eslint-disable-next-line
    list.map((e) => {
      let newElem = included?.filter(
        (item) =>
          item.type === "owners" && item.id.toString() === e.id.toString()
      )[0];
      userList.push(newElem);
    });
    return userList;
  };
  const setOrgs = (list) => {
    return list
      .map((e) =>
        included?.find(
          (item) =>
            item.type === "orgs" && item.id.toString() === e.id.toString()
        )
      )
      .filter(Boolean);
  };

  const getOrgTitles = (orgIds) => {
    return orgIds.map((orgId) => {
      const org = included.find(
        (orgs) => orgs.id === orgId && orgs.type === "orgs"
      );

      return {
        id: org?.attributes?.["org-id"],
        title: org?.attributes?.["org-master"]?.data?.attributes?.["org-title"],
      };
    });
  };

  const moreOrgs = (orgIds) => {
    return orgIds.map((org) => ({
      id: org?.attributes?.["org-id"],
      title: org?.attributes?.["org-master"]?.data?.attributes?.["org-title"],
    }));
  };

  const handleChange = (selectedOptions) => {
    setSelectedValue(selectedOptions);
  };

  const handleProcessClick = (processId) => {
    const selectedProcess = processDetails.find((p) => p.id === processId);
    setActivePro(selectedProcess);
  };

  const handleButtonClick = (buttonId) => {
    setLoading(true);
    setActiveBtn(buttonId);
    setProcessDetails([]);
  };

  const handleEmployeeModalClick = async (owner) => {
    const empList = setUsers(owner.relationships.owners.data);
    setUsersData(empList);
    setShowEmpModal(true);
  };
  const handleOrgModalClick = (orgList) => {
    const data = setOrgs(orgList.relationships.orgs.data);
    setShowOrgModal(true);
    const orgTitles = moreOrgs(data);
    setOrgValues(orgTitles);
  };

  const handleGroupSearch = (selectedGroups) => {
    setGroupQuery(selectedGroups);
  };

  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);
    setOrgList(res?.data?.data);
  };

  const handleCancel = () => {
    setDropdownShow(false);
  };

  const handleApply = (filter) => {
    setFinalOrgQuery(filter.orgQuery);
    setFinalGrpQuery(filter.groupQuery);
    setShowConnection(filter.parentChildQuery);
    setDropdownShow(false);
    setCurrentPage(1);
  };

  const handleClear = () => {
    setFinalOrgQuery(null);
    setFinalGrpQuery(null);
    setShowConnection(false);
  };

  const handleInputChange = (e) => {
    setCurrentPage(1);
    if (e.target.value) {
      setQuery(e.target.value);
    } else {
      setQuery("");
    }
  };

  const renderButton = (buttonId, label) => (
    <Button
      className={`form-select-btn d-flex flex-row justify-content-center w-50 ${
        activeBtn === buttonId ? "form-select-activeBtn" : ""
      }`}
      onClick={() => handleButtonClick(buttonId)}
    >
      <div>{label}</div>
      <div className="mx-2">
        <ImageSvgs
          name={activeBtn === buttonId ? buttonId : `${buttonId}Disable`}
        />
      </div>
    </Button>
  );

  const handleAddProcess = () => {
    sessionStorage.setItem("processFlag", "true");
    sessionStorage.removeItem("updateId");
    sessionStorage.removeItem("formData");
    sessionStorage.removeItem("processEditData");
    sessionStorage.removeItem("processPayload");
    sessionStorage.removeItem("processConfig");
    navigate("/manage_process?page=addProcess", { replace: true });
  };

  const handleOrgOnChange = (value) => {
    setOrgQuery(value);
  };

  const handleOrgInpuChange = (text) => {
    handleOrgSearch(text);
  };

  const dropdowns = [
    {
      inputType: "multi-select",
      queryKey: "groupQuery",
      options: groupList?.map((value) => ({
        type: value.type,
        name: value.attributes.title,
        id: value.id,
      })),
      placeholder: "Search by Groups",
      multi: false,
      onChange: handleGroupSearch,
      selected: groupQuery,
      label: "Groups",
    },
    {
      inputType: "typeahead",
      queryKey: "orgQuery",
      options: orgList?.map((value) => ({
        type: value.type,
        name: value.attributes["org-title"],
        id: value.id,
      })),
      onChange: handleOrgOnChange,
      onInputChange: handleOrgInpuChange,
      placeholder: "Search by OrgCode",
      selected: orgQuery,
      emptyLabel: "No matches found.",
      label: "OrgCode",
    },
    activeBtn === "mapView"
      ? {
          inputType: "multi-select",
          queryKey: "parentChildQuery",
          options: options,
          placeholder: "Include Parent and Child Nodes",
          multi: false,
          onChange: handleChange,
          selected: selectedValue,
          label: "Include Parent and Child Nodes",
        }
      : null,
  ].filter(Boolean);

  return (
    <div className="process-user-page">
      <div className="process-content">
        <Container fluid className="mx-0 me-0 main-process-container">
          <Row>
            <Col
              xs={6}
              xl={6}
              className="d-flex justify-content-start w-50 p-0"
            >
              <ButtonGroup className="my-2 w-50">
                {renderButton("cardView", "Card View")}
                {renderButton("mapView", "Map View")}
              </ButtonGroup>
            </Col>
            <Col xs={6} xl={6} className="d-flex justify-content-end w-50 p-0">
              <div className="d-flex pt-1">
                {adminCheck && adminCheck === "true" ? (
                  <Button
                    disabled={allLoading}
                    onClick={handleAddProcess}
                    className="app-tertiary-btn"
                  >
                    Add Process
                  </Button>
                ) : null}
                <div className="app-primary-input-field custom-search pt-1 ms-3">
                  <input
                    type="search"
                    id="message"
                    value={query}
                    placeholder="Search Process..."
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* for testing and further reference */}
              {/* <ProcessFilterDropdown
                dropdownShow={dropdownShow}
                setDropdownShow={setDropdownShow}
                groupList={groupList}
                orgList={orgList}
                groupQuery={groupQuery}
                orgQuery={orgQuery}
                handleGroupSearch={handleGroupSearch}
                handleOrgSearch={handleOrgSearch}
                handleCancel={handleCancel}
                handleApply={handleApply}
                setOrgQuery={setOrgQuery}
                activeBtn={activeBtn}
                handleChange={handleChange}
                selectedValue={selectedValue}
              /> */}
              <GenericFilter
                show={dropdownShow}
                onToggle={() => setDropdownShow(false)}
                onSelect={() => setDropdownShow(!dropdownShow)}
                dropdowns={dropdowns}
                onApply={handleApply}
                onCancel={handleCancel}
                onClear={handleClear}
                title="Advanced Filters"
              />
            </Col>
          </Row>
        </Container>
        <div className="my-2 grid-container">
          {!loading ? (
            processDetails.length > 0 ? (
              <ProcessViewContent
                activeView={activeBtn}
                processDetails={processDetails}
                activePro={activePro}
                getOrgTitles={getOrgTitles}
                handleProcessData={handleProcessClick}
                navigate={navigate}
                included={included}
                groupList={groupList}
                currentPage={currentPage}
                handleEmployeeModalClick={handleEmployeeModalClick}
                handleOrgModalClick={handleOrgModalClick}
                totalPage={totalPage}
                onPageChange={handlePageChange}
                groupQuery={finalGrpQuery}
                orgQuery={finalOrgQuery}
                allLoading={allLoading}
                showConnection={showConnection}
                setDeleteFlag={setDeleteFlag}
              />
            ) : (
              <div className="d-flex justify-content-center no-data-check p-5 ">
                <div>
                  <ImageSvgs name="no_data" />
                </div>
              </div>
            )
          ) : (
            <div className="custom-loader">
              <Loader />
            </div>
          )}
        </div>

        {showEmpModal === true ? (
          <UserModal
            title={"Process Owners and Deputies"}
            listdata={usersData}
            show={showEmpModal}
            onHide={() => setShowEmpModal(false)}
          />
        ) : (
          ""
        )}
        {showOrgModal === true ? (
          <ProcessOrgDetailModal
            show={showOrgModal}
            title={"List of Org Codes"}
            onHide={() => setShowOrgModal(false)}
            orgdata={orgValues}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
