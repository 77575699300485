import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ButtonGroup, Button } from "react-bootstrap";
import "./FinergyDashboard.scss";

const FinergyDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    if (location.pathname.includes("overview")) {
      setActiveTab("overview");
    } else if (location.pathname.includes("learning")) {
      setActiveTab("learning");
    }
  }, [location.pathname]);

  useEffect(() => {
    setActiveTab("overview");
    navigate("/landscape/finergy/overview");
    // eslint-disable-next-line
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "overview") {
      navigate("/landscape/finergy/overview");
    } else if (tab === "learning") {
      navigate("/landscape/finergy/learning");
    }
  };

  return (
    <div className="finDashboard">
      <div className="main-internal-page mx-auto mt-4">
        <div className="page-header d-flex flex-column flex-md-row align-items-center border-bottom tabs-section">
          <div className="w-25">
            <h2 className="fw-bold">FINergy Academy</h2>
          </div>
          <div className="d-flex flex-wrap justify-content-start">
            <ButtonGroup
              aria-label="First group"
              className="d-flex flex-wrap justify-content-start"
            >
              <Button
                className={`tools-tab-btn ${
                  activeTab === "overview" ? "active" : ""
                }`}
                variant="primary"
                onClick={() => handleTabChange("overview")}
              >
                Overview
              </Button>
              <Button
                className={`tools-tab-btn ${
                  activeTab === "learning" ? "active" : ""
                }`}
                variant="primary"
                onClick={() => handleTabChange("learning")}
              >
                Learning
              </Button>
              <Button
                className="tools-tab-btn"
                variant="primary"
                onClick={() => alert("Networking Page is under development")}
              >
                Networking
              </Button>
              <Button
                className="tools-tab-btn"
                variant="primary"
                onClick={() => alert("Development Page is under development")}
              >
                Development
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div className="mt-3">
          <div className="finergy-route">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinergyDashboard;
