import React, { useEffect } from "react";
import TanstackReactFinTable from "../CommonComponents/TanstackReactFinTable";
import { Button } from "react-bootstrap";
import DATA from "./catalogue.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageSvgs from "../ImageSvgs";
import { Nav } from "react-bootstrap";
import "./FinergyLearningAdminPage.scss";
import { useNavigate } from "react-router-dom";

const FinergyLearningAdminPage = () => {
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "title",
        id: "title",
        cell: (info) => info.getValue(),
        header: () => <span>Title</span>,
        footer: (props) => props.column.id,
      },
      {
        id: "actions",
        header: () => <span>Actions</span>,
        cell: ({ row }) => (
          <div>
            <EditIcon
              data-testid="edit-icon"
              style={{ cursor: "pointer", marginRight: "10px" }}
              onClick={() => handleEdit(row.original)}
            />
            <DeleteIcon
              data-testid="delete-icon"
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.original)}
            />
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const [data, setData] = React.useState(() => DATA);

  useEffect(() => {
    setData(DATA);
  }, []);
  const handleEdit = (row) => {
    // Implement your edit logic here
    // console.log("Edit row:", row);
  };

  const handleDelete = (row) => {
    // Implement your delete logic here
    // console.log("Delete row:", row);
  };
  const onRowClick = (row) => {
    // Implement your row click logic here
    // console.log("Row clicked:", row);
  };
  return (
    <>
      <div className="p-5">
        <div className="responsive-container">
          <div className="back-icon d-flex">
            <Nav.Link onClick={() => navigate(-1)} data-testid="back-button">
              {" "}
              <ImageSvgs name="goBack" />
            </Nav.Link>
            <span className="ps-1"> Back </span>
          </div>
          <div className="sub-bar d-flex justify-content-between">
            <p className="main-title" data-testid="main-title">
              Manage Finance Fields({data.length})
            </p>
            <div className="search-div d-flex">
              <Button
                className="app-secondary-btn"
                onClick={() => {
                  // console.log("AAdd New Finance Field");
                }}
                data-testid="add-button">
                Add New Finance Field
              </Button>
            </div>
          </div>
          <div className="table-wrapper">
            <TanstackReactFinTable
              {...{
                data,
                columns,
                onRowClick: (row) => ({
                  onClick: () => onRowClick(row.original),
                }),
              }}
              data-testid="fin-table"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FinergyLearningAdminPage;
