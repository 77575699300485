import React, { useEffect, useState } from "react";
import OverviewPlaceholder from "./OverviewPlaceholder";
import MainComp from "../TrainingsDashboard/MainComp";
import "./FinergyOverviewPage.scss";
import { getBaseUrl } from "../../config/environment";
import DynamicContent from "../CommonComponents/DynamicContent";

const URL_TO_FETCH_FINERGY_INTRODUCTION_HTML = `${getBaseUrl()}/configurations/FiNergy/FiNergy_Introduction.html`;
const URL_TO_FETCH_FINERGY_INTRODUCTION_VIDEO = `${getBaseUrl()}/configurations/FiNergy/FINergy_Introduction.mp4`;

const FinergyOverviewPage = () => {
  const [finergyIntroduction, setFinergyIntroduction] = useState("");

  useEffect(() => {
    const fetchIntroduction = async () => {
      try {
        const response = await fetch(URL_TO_FETCH_FINERGY_INTRODUCTION_HTML);
        const data = await response?.text();
        data ? setFinergyIntroduction(data) : setFinergyIntroduction("");
      } catch (error) {
        setFinergyIntroduction("Error fetching the introduction.");
      }
    };

    fetchIntroduction();
  }, []);

  return (
    <div className="overview-container my-4">
      <div className="row">
        <div className="col-md-12">
          <div className="overview-comp data">
            <div className="d-flex title">
              <p className="fw-bold fs-4 mb-2 m-3">About FINergy Academy </p>
            </div>
            <div className="d-flex flex-wrap align-items-start">
              <div className="video-container p-3">
                <video controls data-testid="video-element" className="video">
                  <source
                    src={URL_TO_FETCH_FINERGY_INTRODUCTION_VIDEO}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div
                className="content-container p-3"
                data-testid="content-container">
                <div className="mt-2 fs-4">
                  <DynamicContent htmlContent={finergyIntroduction} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6  training-comp">
          <div className="overview-comp">
            <MainComp />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="overview-comp">
            <OverviewPlaceholder title="Upcoming Finance Learning Hours" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="overview-comp">
            <OverviewPlaceholder title="Trending Learnings" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="overview-comp">
            <OverviewPlaceholder title="Your Badge Gallery" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinergyOverviewPage;
