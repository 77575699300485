import React, { useState } from "react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";
const listData = require("./leftNavigationData.json");

const styles = (theme) => ({
  listItem: {
    "&,&:focus": {
      backgroundColor: theme.palette.background.paper,
    },
    border: "none",
    padding: "5px",
  },
  important: {
    color: "rgb(138, 0, 229)",
    fontWeight: "bold",
    paddingLeft: 10,
  },
});

// Helper function to update the important property

const updateImportantProperty = (data, currentItem) => {
  data.forEach((item) => {
    if (item.title !== currentItem.title) {
      item.important = false;
    }
    if (item.children && item.children.length > 0) {
      updateImportantProperty(item.children, currentItem);
    }
  });
};

const NestedList = ({ items, classes }) => {
  const [open, setOpen] = useState({});
  const [selected, setSelected] = useState(null);

  const handleClick = (item) => {
    // console.log("item clicked", item);
    // console.log(" list data", listData);
    // Update the important property for all items
    updateImportantProperty(listData, item);
    if (item.children !== undefined) {
      item.important = false;
    } else {
      item.important = true;
    }
    // item.important = true;
    setOpen((prevOpen) => ({
      ...prevOpen,
      [item.title]: !prevOpen[item.title],
    }));

    if (!item.children || item.children.length === 0) {
      setSelected(item.title);
    } else {
      const hasSelectedChild = item.children.some(
        (child) => selected === child.title
      );
      if (hasSelectedChild) {
        setSelected(null);
      } else {
        setSelected(item.title);
      }
    }
  };

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {items.map((item) => (
        <React.Fragment key={item.title}>
          <ListItemButton
            onClick={() => handleClick(item)}
            className={classes.listItem}
            divider>
            <ListItemText
              primary={item.title}
              classes={{ primary: item.important ? classes.important : "" }}
            />
            {item.children &&
              item.children.length > 0 &&
              (open[item.title] ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
          {item.children && item.children.length > 0 && (
            <Collapse in={open[item.title]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 1, pt: 1 }}>
                <NestedList items={item.children} classes={classes} />
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

const FinNestedList = withStyles(styles)(({ classes }) => {
  return <NestedList items={listData} classes={classes} />;
});

export default FinNestedList;
