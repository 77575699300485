import { React, useState, useEffect } from "react";
import Graph from "react-graph-vis";
import ProcessDetailsPage from "./ProcessDetailPage";
import { useNavigate } from "react-router-dom";
import "./ProcessNetworkGraph.scss";
import "vis-network/styles/vis-network.min.css";

export default function ProcessNetworkGraph(props) {
  const [showDetail, setShowDetail] = useState(false);
  // eslint-disable-next-line
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [groupOptions, setGroupOptions] = useState({});
  const showConnection =
    props?.selectedValue?.length > 0 && props.selectedValue[0]?.id;

  const navigate = useNavigate();

  function formatNodeLabel({ title, orgTitles, group }) {
    const groupLabel = group && group !== "Default" ? group : "N/A";
    return `${title}\n\n<code>${groupLabel}</code>`;
  }

  const orgTitleMap = props?.included?.reduce((acc, item) => {
    if (item.type === "orgs") {
      const orgId = item.id;

      const orgTitle =
        item.attributes["org-master"].data.attributes["org-title"];

      if (orgId && orgTitle) {
        acc[orgId] = orgTitle;
      }
    }
    return acc;
  }, {});

  const generateGroupOptions = (groupData) => {
    const groups = groupData.reduce((acc, group) => {
      const {
        title,
        "node-color": color,
        "font-color": fontColor,
      } = group.attributes;

      acc[title] = {
        color: {
          background: color || "#FFFFFF",
          border: color || "#8a00e5",
        },
        font: {
          color: fontColor ? fontColor : "",
        },
      };

      return acc;
    }, {});

    return groups;
  };

  useEffect(() => {
    setGroupOptions(generateGroupOptions(props.groupList));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const nodeMap = new Map();
    const newEdges = new Set();

    props.data.forEach((process) => {
      const orgIds = process.relationships.orgs.data.map((org) => org.id);
      const orgTitles = orgIds.map((orgId) => orgTitleMap[orgId] || null);

      const node = {
        id: process.id.toString(),
        label: formatNodeLabel({
          title: process.attributes.title,
          orgTitles: orgTitles,
          group:
            process.attributes["process-group"]?.data?.attributes.title ||
            "Default",
          nodeColor:
            process.attributes["process-group"]?.data?.attributes[
              "node-color"
            ] || "#CCCCCC",
          fontColor:
            process.attributes["process-group"]?.data?.attributes[
              "font-color"
            ] || "#CCCCCC",
        }),
        shape: "box",
        group:
          process.attributes["process-group"]?.data?.attributes.title ||
          "Default",
      };

      nodeMap.set(node.id, node);

      if (process?.relationships?.["parent-processes"]?.data.length > 0) {
        process.relationships["parent-processes"].data.forEach(
          (processDataItem) => {
            const includedData = props.included.find(
              (data) => data.id === processDataItem.id
            );

            if (includedData) {
              const fromId = includedData.attributes["child-id"];
              const toId = includedData.attributes["parent-id"];

              newEdges.add(`${fromId}-${toId}`);

              const parentProcess = props.data.find(
                (p) => p.id === parseInt(toId)
              );
              if (
                parentProcess &&
                parentProcess.relationships["parent-processes"].data.includes(
                  parseInt(fromId)
                )
              ) {
                newEdges.add(`${toId}-${fromId}`);
              }
            }
          }
        );
      }

      if (showConnection === 2) {
        const deliveredTo = process.attributes["delivered-to"]?.data || [];
        const receivedFrom = process.attributes["received-from"]?.data || [];

        deliveredTo.forEach((delivered) => {
          const toId = delivered.id;

          if (!nodeMap.has(toId)) {
            const deliveredNode = {
              id: toId,
              label: formatNodeLabel({
                title: delivered.attributes.title,
                orgTitles: orgTitles,
                group:
                  delivered.attributes["process-group"]?.data?.attributes
                    .title || "Default",
                nodeColor:
                  delivered.attributes["process-group"]?.data?.attributes[
                    "node-color"
                  ] || "#CCCCCC",
                fontColor:
                  delivered.attributes["process-group"]?.data?.attributes[
                    "font-color"
                  ] || "#CCCCCC",
              }),
              shape: "box",
              group:
                delivered.attributes["process-group"]?.data?.attributes.title ||
                "Default",
            };
            nodeMap.set(toId, deliveredNode);
          }
          newEdges.add(`${process.id.toString()}-${toId}`);
        });

        receivedFrom.forEach((received) => {
          const fromId = received.id;

          if (!nodeMap.has(fromId)) {
            const receivedNode = {
              id: fromId,
              label: formatNodeLabel({
                title: received.attributes.title,
                group:
                  received.attributes["process-group"]?.data?.attributes
                    .title || "Default",
                nodeColor:
                  received.attributes["process-group"]?.data?.attributes[
                    "node-color"
                  ] || "#CCCCCC",
                fontColor:
                  received.attributes["process-group"]?.data?.attributes[
                    "font-color"
                  ] || "#CCCCCC",
              }),
              shape: "box",
              group:
                received.attributes["process-group"]?.data?.attributes.title ||
                "Default",
            };
            nodeMap.set(fromId, receivedNode);
          }
          newEdges.add(`${fromId}-${process.id.toString()}`);
        });
      }
    });

    const updatedNodes = Array.from(nodeMap.values());
    const uniqueEdges = Array.from(newEdges).map((edge) => {
      const [from, to] = edge.split("-");
      return { from, to, smooth: { enabled: true } };
    });

    setNodes(updatedNodes);
    setEdges(uniqueEdges);
    // eslint-disable-next-line
  }, [props.data, props.included, showConnection]);

  const handleClose = () => {
    setShowDetail(false);
  };
  const graph = {
    nodes: nodes,
    edges: edges,
  };

  const options = {
    interaction: { hover: true },
    autoResize: true,
    nodes: {
      shape: "box",
      size: 600,

      borderWidth: 3,
      font: {
        multi: true,
        size: 16,
        background: "none",
        align: "left",
      },
      chosen: {
        node: function (values, id, selected, hovering) {
          if (selected) {
            values.borderWidth = 4;
            values.borderColor = values.color;
            values.font = {
              multi: true,
              size: 16,
              background: "none",
              align: "left",
              bold: true,
            };
          } else {
            values.borderWidth = 3;
          }
        },
      },
    },
    groups: groupOptions,

    edges: {
      color: { color: "grey", hover: "black" },
      width: 2,
    },

    height: "700px",

    physics: {
      enabled: true,
      solver: "forceAtlas2Based",
      forceAtlas2Based: {
        gravitationalConstant: -500,
        centralGravity: 0.01,
        springConstant: 0.1,
        damping: 4,
        avoidOverlap: 0.5,
      },
      stabilization: {
        iterations: 100,
        updateInterval: 25,
        onlyDynamicEdges: false,
        fit: true,
      },
    },

    layout: {
      improvedLayout: true,
      randomSeed: 4,
      hierarchical: {
        enabled: false,
      },
    },
  };

  const filterDataById = (id) => {
    return (
      props.data.find((item) => item.id.toString() === id.toString()) || null
    );
  };

  const events = {
    select: function (event) {
      // eslint-disable-next-line
      var { nodes, edges } = event;
    },
    click: function (event) {
      var { nodes, edges } = event;

      if (nodes.length > 0) {
        const processId = nodes[0];
        const filteredData = filterDataById(processId);
        setSelectedProcess(filteredData);

        const queryParams = new URLSearchParams({
          process_id: processId,
          view: props.view,
        });

        let selectedProcessFilter = {};

        if (props.orgQuery?.length > 0) {
          selectedProcessFilter.orgQuery = props.orgQuery;
        }

        if (props.groupQuery?.length > 0) {
          selectedProcessFilter.groupQuery = props.groupQuery;
        }

        if (Object.keys(selectedProcessFilter).length > 0) {
          const encodedFilter = encodeURIComponent(
            JSON.stringify(selectedProcessFilter)
          );
          queryParams.append("selectedProcessFilter", encodedFilter);
        }

        navigate(`/landscape/process?${queryParams.toString()}`, {
          state: { prevPath: window.location.href },
        });

        setShowDetail(true);
      } else if (edges.length > 0) {
      }
    },
  };

  return (
    <>
      <Graph graph={graph} options={options} events={events} />

      {showDetail === true ? (
        <ProcessDetailsPage
          hide={handleClose}
          show={showDetail}
          handleClose={handleClose}
          activeBtn={props.view}
          included={props.included}
          setDeleteFlag={props.setDeleteFlag}
        />
      ) : null}
    </>
  );
}
