import React, { useState, useEffect, useRef } from "react";
import wernerIcon from "../../assets/images/wernerIcon.svg";
import maxChatBot from "../../assets/images/maxChatBot.svg";
import minChatBot from "../../assets/images/minChatBot.svg";
import { getBaseUrl, getWernerUrl } from "../../config/environment";
import { postData } from "../../services/apiService";
import DislikeModal from "./DislikeModal";
import FeedbackButtons from "./FeedbackButtons";
import "./FinChatBot.scss";
import FormatString from "./FormatString";
import { CATEGORIES } from "../../config/Constants";
const closeIcon =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=";

const FinChatBot = () => {
  const profileImg = JSON.parse(
    sessionStorage.getItem("userData")
  ).profilePicture;

  const [feedbackData, setFeedbackData] = useState(null);
  const [feedbackList, setFeedbackList] = useState(
    JSON.parse(sessionStorage.getItem("feedbackList")).data
  );

  const [msgList, setMsgList] = useState(
    JSON.parse(sessionStorage.getItem("chatHistory")).data
  );

  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const messagesEndRef = useRef(null);
  const [spanColors, setSpanColors] = useState(
    CATEGORIES.map((category) =>
      category.value === "all" ? "#f3e0ff" : "white"
    )
  );
  const [fullScreen, setFullScreen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [search_area, setSearchArea] = useState("all");

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgList, isOpen]);

  const handleSpanClick = async (index, value) => {
    setSpanColors((prevColors) =>
      prevColors.map((color, i) => (i === index ? "#f3e0ff" : "white"))
    );
    setSearchArea(value);
  };

  const handleSendMessage = async () => {
    if (input.trim() === "") return;

    setMsgList([...msgList, { sender: "user", message: input }]);
    setInput("");
    setIsLoading(true);
    setFeedbackData(null);
    let tempMsgList = msgList && msgList;

    const chatData = tempMsgList.slice(-6);
    const past_conversations = [];
    for (let i = 0; i < chatData.length; i++) {
      if (chatData[i].sender === "bot" && chatData[i].userInfoWithQuestion) {
        past_conversations.push({
          human: chatData[i].userInfoWithQuestion,
          ai: chatData[i].message,
        });
        i++; // Skip the next message as it is the user's response to the bot's question
      }
    }

    const payload = {
      data: {
        type: "werner",
        attributes: {
          search_area: search_area,
          question: input,
          "past-conversations": past_conversations,
        },
      },
    };
    const newMessage = {
      id: (tempMsgList.length + 1).toString(),
      message: input,
      direction: "outgoing",
      links: [],

      sender: "user",
    };
    tempMsgList.push(newMessage);

    try {
      const chatRes = await postData(`${getWernerUrl()}werner`, payload);
      if (chatRes) {
        sessionStorage.setItem(
          "chatModel",
          chatRes.data.data.attributes["chat-completion-model-name"]
        );

        const chatGPTResponse = {
          id: (tempMsgList.length + 1).toString(),
          message: chatRes.data.data.attributes.answer,
          links: chatRes.data.data.attributes.Links,
          userInfoWithQuestion: chatRes.data.data.attributes.user,
          chatModel: chatRes.data.data.attributes["chat-completion-model-name"],
          direction: "incoming",
          sender: "bot",
        };
        tempMsgList.push(chatGPTResponse);

        setMsgList(tempMsgList);
        sessionStorage.setItem(
          "chatHistory",
          JSON.stringify({
            data: tempMsgList,
          })
        );
      }
    } catch (error) {
      const chatGPTResponse = {
        id: (tempMsgList.length + 1).toString(),
        message: "Sorry, that took longer than expected. Please try again.",
        links: [],
        chatModel: null,
        direction: "incoming",
        sender: "bot",
      };
      tempMsgList.push(chatGPTResponse);
      setMsgList((prevMessages) => [
        ...prevMessages,
        {
          sender: "bot",
          id: chatGPTResponse.id,
          message: chatGPTResponse.message,
          links: chatGPTResponse.links,
          chatModel: chatGPTResponse.chatModel,
          feedback: null,
        },
      ]);

      sessionStorage.setItem(
        "chatHistory",
        JSON.stringify({
          data: tempMsgList,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const profileImgURL =
    profileImg === "null" || profileImg === ""
      ? "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png"
      : `${getBaseUrl()}${profileImg}`;

  const DefaultMsg = () => {
    return (
      <p className="mb-0 mx-auto text-muted">
        Werner uses AI
        {sessionStorage.getItem("chatModel")
          ? ` including ${sessionStorage.getItem("chatModel")}`
          : ""}
        . Check for mistakes.
      </p>
    );
  };

  return (
    <>
      {/* Floating Button */}
      {!isOpen && (
        <div className="floatingButton" onClick={() => setIsOpen(true)}>
          <img className="icon" src={wernerIcon} alt="Title Icon" />
        </div>
      )}

      {/* Chatbot Window */}
      {isOpen && (
        <div>
          <div className="floatingButton" onClick={() => setIsOpen(false)}>
            <img className="close-icon" src={closeIcon} alt="Close Icon" />
          </div>
          <div
            className={`chatbot-container ${fullScreen ? "fullScreen" : ""}`}>
            <div className="d-flex flex-row chatbot-header">
              <>
                <img className="icon" src={wernerIcon} alt="Title Icon" />
              </>
              <span className="d-flex flex-row justify-content-between ms-2 w-100">
                <span className="d-flex flex-column my-auto">
                  <p className="fw-bold mb-0 fs-5">Werner</p>
                  <p className="fw-normal mb-0 fs-6">Your virtual assistant</p>
                </span>
                <button
                  data-toggle="tooltip"
                  data-placement="top"
                  title={fullScreen ? "Minimize" : "Maximize"}
                  className="me-1 chatbot-header-icon"
                  onClick={() => {
                    setFullScreen(!fullScreen);
                  }}>
                  <img
                    src={fullScreen ? minChatBot : maxChatBot}
                    alt="header-icon"
                  />
                </button>
              </span>
            </div>

            <div className="messagesContainer">
              {msgList.map((msg, index) => (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className={`message ${
                      msg.sender === "user" ? "userMessage" : "botMessage"
                    }`}>
                    {msg.sender === "bot" && (
                      <img
                        className="msg-icon"
                        src={wernerIcon}
                        alt="Title Icon"
                      />
                    )}
                    {msg.sender === "user" && (
                      <img
                        className="msg-icon"
                        src={profileImgURL}
                        alt="Title Icon"
                      />
                    )}
                    <div className="message-bg me-2">
                      {FormatString(msg.message)}
                      {msg?.links?.length > 0 && (
                        <div className="d-flex mt-2">
                          <b className="me-2">References:</b>
                          <span className="d-flex">
                            {msg?.links.map((elem, index) => (
                              <span key={index} className="reference-link">
                                <a
                                  className="werner-links me-2 px-1 fw-bold"
                                  href={elem.split(" ").join("")}
                                  target="_blank"
                                  rel="noreferrer"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={elem.split(" ").join("")}>
                                  {index + 1}
                                </a>
                              </span>
                            ))}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  {msg.sender === "bot" && (
                    <div>
                      {
                        // <FeedbackButtons id={msg.id} />
                        <FeedbackButtons
                          id={msg.id}
                          feedbackList={feedbackList}
                          setFeedbackList={setFeedbackList}
                          msgList={msgList}
                          setModalShow={setModalShow}
                          setFeedbackData={setFeedbackData}
                        />
                      }
                    </div>
                  )}
                </React.Fragment>
              ))}
              {isLoading && (
                <div className="message botMessage my-5">
                  <img className="msg-icon" src={wernerIcon} alt="Title Icon" />
                  <div className="message-bg loader">Typing...</div>
                </div>
              )}
              <div className="center-text">
                {sessionStorage.getItem("chatModel") && <DefaultMsg />}
              </div>
              <div ref={messagesEndRef} />
            </div>
            <div className="chatbot-footer">
              <div className="categories inputContainer">
                {CATEGORIES.map((category, index) => (
                  <span
                    key={category.value}
                    className={`badge rounded-pill category-badge text-bg-${spanColors[index]}`}
                    onClick={() => handleSpanClick(index, category.value)}
                    style={{
                      backgroundColor: spanColors[index],
                    }}>
                    {category.label}
                  </span>
                ))}
              </div>
              <div className="inputContainer">
                <input
                  type="text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                  placeholder="Type a message..."
                  className="input w-75"
                />
                <button onClick={handleSendMessage} className="sendButton m-0">
                  <img
                    src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNTM1LjUgNTM1LjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUzNS41IDUzNS41OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGcgaWQ9InNlbmQiPgoJCTxwb2x5Z29uIHBvaW50cz0iMCw0OTcuMjUgNTM1LjUsMjY3Ljc1IDAsMzguMjUgMCwyMTYuNzUgMzgyLjUsMjY3Ljc1IDAsMzE4Ljc1ICAgIiBmaWxsPSIjY2JjYmNiIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=="
                    alt="Send"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="feedback-modal">
        {modalShow ? (
          <DislikeModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            feedbackData={feedbackData && feedbackData}
            feedbackList={feedbackList}
            setFeedbackList={setFeedbackList}
          />
        ) : null}
      </div>
    </>
  );
};

export default FinChatBot;
