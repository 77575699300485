import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/cjs/styles/prism";

const FormatString = (response) => {
  const parts = response.split("```");

  const boldPattern = /\*\*(.*?)\*\*/g;
  const boldUnderlinePattern = /###(.*?)###/g;

  // Helper function to format text with bold and bold-underline styles
  const formatText = (text) => {
    return text
      .split(boldPattern)
      .map((part, index) => {
        if (index % 2 === 1) {
          return <strong key={index}>{part}</strong>;
        }

        return part.split(boldUnderlinePattern).map((subPart, subIndex) => {
          if (subIndex % 2 === 1) {
            return (
              <strong
                key={`${index}-${subIndex}`}
                style={{ textDecoration: "underline" }}>
                {subPart}
              </strong>
            );
          }
          return subPart;
        });
      })
      .flat(); // Flatten the nested array structure
  };

  const components = parts.map((part, index) => {
    if (index % 2 === 0) {
      // For text sections, handle new lines by splitting on \n and wrapping each line in a <p> tag
      const lines = part.split("\n").map((line, lineIndex) => (
        <p className="m-0" key={`${index}-${lineIndex}`}>
          {formatText(line)}
        </p>
      ));
      return <div key={index}>{lines}</div>;
    } else {
      // Code section, render in SyntaxHighlighter
      return (
        <SyntaxHighlighter
          key={index}
          style={vscDarkPlus}
          language="javascript"
          wrapLongLines={true}>
          {part.trim()}
        </SyntaxHighlighter>
      );
    }
  });

  return <>{components}</>; // Render the components as a React fragment
};

export default FormatString;
