import React, { useEffect, useMemo, useState } from "react";
import Loader from "../Loader/Loader";
import ImageSvgs from "../ImageSvgs";
import ResponsiveGrid from "../CommonComponents/ResponsivePageLayout/ResponsiveGrid";
import Button from "react-bootstrap/Button";
import "./FinergyLearningPage.scss";
import FinNestedList from "./FinNestedList";
import { Link } from "react-router-dom";
import { getData } from "../../services/apiService";
import { UserModal } from "../../utils/toolsUtils";
import { getHostUrl } from "../../config/environment";
import { MdOpenInNew } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import OwnerProfile from "../CommonComponents/OwnerProfile";

const data = require("./learningData.json");

const FinergyLearningPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [totalPages, setTotalPages] = useState(1000);
  const [activeBtn, setActiveBtn] = useState("cardView");
  const [showUserModal, setShowUserModal] = useState(false);
  const [ownerData, setOwnerData] = useState(false);

  const [res, setRes] = React.useState(() => []);

  useEffect(() => {
    const fetchData = async () => {
      const param = `${getHostUrl()}courses?template=id,courses_title,courses_average_rating,class_delivery_name,courses_redirect_url,course_image,owners`;
      const res = await getData(param);
      let courses = res.data.data;
      setRes(courses);
    };

    fetchData();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const cardFooter = () => {
    return (
      <div className="d-flex justify-content-end">
        <ImageSvgs name="redirect_btn" />
      </div>
    );
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "attributes.courses-title",
        id: "title",
        cell: (info) => {
          const value = info.getValue();
          return value?.length > 100 ? `${value?.substring(0, 100)}...` : value;
        },
        header: () => <span>Title</span>,
        footer: (props) => props.column.id,
        size: 700,
        className: "title-column",
      },
      {
        accessorKey: "attributes.owners.data",
        id: "owners",
        cell: (info) => {
          const owners = info.getValue();
          if (!owners || owners.length === 0) {
            return "-";
          }
          return (
            <div>
              {owners.map((owner, index) => (
                <OwnerProfile key={index} owner={owner} />
              ))}
              {owners?.length > 3 ? (
                <button
                  className="more-user-btn"
                  onClick={async (e) => {
                    e.stopPropagation();
                    setOwnerData(owners);
                    setShowUserModal(true);
                  }}>
                  + {owners?.length - 3} more
                </button>
              ) : null}
            </div>
          );
        },
        header: () => <span>Owners</span>,
        footer: (props) => props.column.id,
        size: 700,
      },
      {
        accessorKey: "attributes.courses-average-rating",
        id: "score",
        cell: (info) => {
          const value = info.getValue();
          return value === 0 ? (
            <>
              <FaStar className="rating_none" /> N/A
            </>
          ) : (
            <>
              <FaStar className="rating_full" /> {value}
            </>
          );
        },
        header: () => <span>Rating</span>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "attributes.class-delivery-name",
        id: "tags",
        cell: (info) => {
          const value = info.getValue(); //.join(", ");
          return value !== "NaN" ? (
            <Link
              style={{
                textDecoration: "none",
              }}
              to={`/landscape/tools?layerId=1&toolId=${info.row.original.id}`}
              state={{
                prevPath: window.location.href,
              }}>
              <span className="tags">{value}</span>
            </Link>
          ) : null;
        },
        header: () => <span>Delivery Type</span>,
        footer: (props) => props.column.id,
      },
      {
        id: "actions",
        cell: ({ row }) => (
          <MdOpenInNew
            className="redirect-btn"
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                row.original.attributes["courses-redirect-url"],
                "_blank"
              );
            }}
          />
        ),
        header: () => <span>Actions</span>,
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const handleButtonClick = (buttonId) => {
    setActiveBtn(buttonId);
  };

  const renderButton = (buttonId, label) => (
    <Button
      className={`form-select-btn d-flex flex-row justify-content-center w-25 ${
        activeBtn === buttonId ? "form-select-activeBtn" : ""
      }`}
      onClick={() => handleButtonClick(buttonId)}>
      <div>{label}</div>
    </Button>
  );

  return (
    <>
      <div className="d-flex w-100">
        {data?.learningData ? (
          <>
            <div className="w-25 left-border">
              {/* <FinLerningLeftNav /> */}
              {<FinNestedList />}
            </div>
            <div className="w-75">
              <ResponsiveGrid
                buttonTitle={"Add Trainings"}
                inputPlaceholder={"Search Courses..."}
                cardData={data.learningData}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                cardFooter={cardFooter}
                firstViewTitle={renderButton("cardView", "Card")}
                secondViewTitle={renderButton("tableView", "Table")}
                activeBtn={activeBtn}
                columns={columns}
                res={res}
              />
            </div>
          </>
        ) : (
          <div className="custom-loader">
            <Loader />
          </div>
        )}
      </div>
      {showUserModal === true ? (
        <UserModal
          title={"Learning Owners:-"}
          listdata={ownerData}
          show={showUserModal}
          onHide={() => setShowUserModal(false)}
        />
      ) : null}
    </>
  );
};

export default FinergyLearningPage;
