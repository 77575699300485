import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ImageSvgs from "../ImageSvgs";
import { postData } from "../../services/apiService";
import { getWernerUrl } from "../../config/environment";

function getIndex(value, arr, prop) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1;
}

const FeedbackButtons = ({
  id,
  feedbackList,
  setFeedbackList,
  msgList,
  setModalShow,
  setFeedbackData,
}) => {
  let tempFeedbackList = feedbackList && feedbackList;
  const [type, setType] = useState(null);
  const [copy, setCopy] = useState(false);

  const getMessage = (index) => {
    if (index >= 0 && index < msgList.length) {
      return msgList[index].message;
    }
    return "";
  };

  return (
    <div className="d-flex ms-5">
      <Button
        id={id ? id : "null"}
        value={100}
        className="feedback-buttons me-1 p-0"
        disabled={
          feedbackList?.filter((elem) => elem.id === id)[0] ? true : false
        }
        data-toggle="tooltip"
        data-placement="top"
        data-testid="like-button"
        title="Like"
        onClick={async (e) => {
          e.currentTarget.classList.add("no-background");
          const index = getIndex(e.currentTarget.id, msgList, "id");
          let finalPayload = {
            data: {
              type: "feedback",
              attributes: {
                feedback_date: new Date(),
                user_id: JSON.parse(sessionStorage.getItem("userData"))
                  .userPrincipalName,
                question: getMessage(index - 1),
                answer: getMessage(index),
                feedback_type: "1",
                feedback: "",
              },
            },
          };
          let headers = {
            Authorization: `Bearer ${sessionStorage.getItem("idToken")}`,
          };
          // eslint-disable-next-line
          const res = await postData(
            `${getWernerUrl()}feedback`,
            finalPayload,
            headers
          );
          if (res?.data) {
            setType("positive");
            tempFeedbackList.push({ id: id, type: "positive" });
            let updatedList = tempFeedbackList;

            setFeedbackList(updatedList);
            sessionStorage.setItem(
              "feedbackList",
              JSON.stringify({
                data: tempFeedbackList,
              })
            );
          }
        }}>
        <ImageSvgs
          name={
            type === "positive" ||
            feedbackList?.filter((elem) => elem.id === id)[0]?.type ===
              "positive"
              ? "active_thumbs_up"
              : "thumbs_up"
          }
        />
      </Button>
      <Button
        id={id ? id : "null"}
        className="feedback-buttons me-2 p-0"
        disabled={
          feedbackList?.filter((elem) => elem.id === id)[0] ? true : false
        }
        data-toggle="tooltip"
        data-placement="top"
        title="Dislike"
        onClick={async (e) => {
          const index = getIndex(e.currentTarget.id, msgList, "id");
          setModalShow(true);
          setType("negative");
          setFeedbackData({
            id: id,
            question: getMessage(index - 1),
            answer: getMessage(index),
          });
        }}>
        <ImageSvgs
          name={
            feedbackList?.filter((elem) => elem.id === id)[0]?.type ===
            "negative"
              ? "active_thumbs_down"
              : "thumbs_down"
          }
        />
      </Button>
      <Button
        id={id ? id : "null"}
        className="feedback-buttons p-0"
        disabled={copy}
        data-toggle="tooltip"
        data-placement="top"
        title="Copy"
        onClick={(e) => {
          const index = getIndex(e.currentTarget.id, msgList, "id");
          navigator.clipboard.writeText(getMessage(index));
          setCopy(true);
          setTimeout(() => {
            setCopy(false);
          }, 1500);
        }}>
        <ImageSvgs name={copy ? "tickMark" : "app_copy"} />
      </Button>
    </div>
  );
};

export default FeedbackButtons;
