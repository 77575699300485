import React from "react";
import GenericCard from "../Cards/GenericCard";
import { getBaseUrl } from "../../config/environment";

export default function OverviewPlaceholder(props) {
  let data = require("./learningData.json");
  return (
    <div className="d-flex flex-column p-4">
      <div className="mb-3">
        <p className="fw-bold fs-4">{props.title}</p>
      </div>
      <div className="placeholder-container d-flex flex-row">
        {data?.learningData
          ?.slice(0, Math.min(3, data.learningData.length))
          .map((data, index) => (
            <div key={data.id}>
              <GenericCard
                id={data.id?.toString()}
                header={data.attributes.title}
                description={data.attributes.desc}
                overlayTriggers={{
                  header: data.attributes.title,
                  description: data.attributes.desc,
                }}
                imageSrc={`${getBaseUrl()}/images/default-images/default-news.jpg`}
                isHover={true}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
